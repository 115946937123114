<template>
  <div>
  <b-container class="base-container search-directory">
    <div class="block-header d-none">
      <!-- <div class="d-none d-lg-block inner-block-header"></div> -->
      <!-- <div class="d-lg-none"></div> -->
      <b-row class="inner-block-header">
        <b-col sm="12" class="mb-2 mt-lg-5 text-center pt-4 pt-md-4 pt-lg-1">
          <h1 class="block-header-title font-weight-bold d-block">{{ $t('SEARCH DIRECTORY') }}</h1>
          <span class="block-header-description mt-3">{{ $t('International Yoga Organization') }}</span>
        </b-col>
      </b-row>
      <!-- <div class="d-none d-lg-block"></div> -->
    </div>
  </b-container>
  <!--<div class="container bg-white mt-3 mb-3">
    <GmapMap
      :center="center"
      :zoom="zoom"
      style="width:100%;  height: 400px;"
      :options="mapOptions"
    >
      <GmapMarker
        :key="index"
        v-for="(marker, index) in markers"
        :position="marker.position"
        @click="toggleInfoWindow(marker,index)"
      ></GmapMarker>
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"

      >
        <b-card no-body class="overflow-hidden" style="max-width: 400px; border:none">
          <b-row no-gutters>
            <b-col md="2" class="d-flex justify-content-center align-items-center">
              <b-link v-if="infoContent.type === 'school'" :to="{ name: 'schoolProfileDetail', params: { schoolId: infoContent.id} }">
                <b-card-img :src="infoContent.image ? infoContent.image : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" alt="Image" class="rounded-0"></b-card-img>
              </b-link>
              <b-link v-else-if="infoContent.type === 'teacher'" :to="{ name: 'teacherProfileDetail', params: { teacherId: infoContent.id} }">
                <b-card-img :src="infoContent.image ? infoContent.image : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" alt="Image" class="rounded-0"></b-card-img>
              </b-link>
               <b-link v-else-if="infoContent.type === 'event'" :to="{ name: 'eventDetail', params: { eventId: infoContent.id } }">
                <b-card-img :src="infoContent.image ? infoContent.image : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'" alt="Image" class="rounded-0"></b-card-img>
              </b-link>
            </b-col>
            <b-col md="10">
              <b-card-body>
                <b-link v-if="infoContent.type === 'school'" :to="{ name: 'schoolProfileDetail', params: { schoolId: infoContent.id} }" class="text-primary-dark" >
                  <b-card-title title-tag="h6">{{ infoContent.name }}</b-card-title>
                </b-link>
                <b-link v-else-if="infoContent.type === 'teacher'" :to="{ name: 'teacherProfileDetail', params: { teacherId: infoContent.id} }" class="text-primary-dark" >
                  <b-card-title title-tag="h6">{{ infoContent.name }}</b-card-title>
                </b-link>
                <b-link v-else-if="infoContent.type === 'event'" :to="{ name: 'eventDetail', params: { eventId: infoContent.id } }" class="text-primary-dark" >
                  <b-card-title title-tag="h6">{{ infoContent.name }}</b-card-title>
                </b-link>
                <b-card-text>
                  {{ infoContent.address }}
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </gmap-info-window>
    </GmapMap>
  </div>-->
  <div class="container-fluid section-1 section-search-directory px-0">
    <b-container class="search-intro-text">
      <b-row>
          <b-col lg="12">
            <h1 class="search-heading">{{ $t('Find Your Perfect Yoga School or Instructor') }}</h1>
            <h3 class="search-subheading">{{ $t('Explore a global directory of certified yoga schools and instructors. Find the best fit for your practice and learning journey') }}</h3>
          </b-col>
        </b-row>
    </b-container>
      <b-container class="base-container-x container align-self-center bg-white">
        <b-row>
          <b-col lg="12" class="block-search px-0">
            <BlockSearch :form="payloadSearch" @submit="onSubmitSeach" @selectTab="onSelectTab"/>
          </b-col>
        </b-row>
      </b-container>
  </div>
  <div class="container pb-4 pt-md-4 pt-4 pt-lg-4 pt-xl-4 bg-white px-4" v-if="isSearch">
    <b-skeleton-wrapper>
      <template>
        <div v-if="isLoading">
        <b-card class="border-0">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
        <b-card class="mt-3 border-0">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
        <b-card class="mt-3 border-0">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
        </div>
      </template>
      <div v-if="!isLoading && searchResult">
        <h6 class="text-muted">{{ $t('Found {number} entries of your search', { number: (searchResult ? searchResult.count : 0) }) }}</h6>
        <h4 class="text-primary-dark" v-if="payloadSearch.region.text">{{ $t('Search Result For “{region}”', { region: payloadSearch.region.text }) }}</h4>
        <ResultSearchItem :results="searchResult" :searchType="payloadSearch.search_type"/>
        <b-row class="d-flex px-3 mt-3 d-block d-lg-none justify-content-center" v-if="searchResult.next">
          <b-button pill variant="light" class="btn-outline-danger px-4" @click="nextPageSchool()">{{ $t('View more')}}</b-button>
        </b-row>
        <b-row class="px-3 mt-4 d-none d-lg-flex justify-content-between" v-if="searchResult">
          <div class="mt-2" v-if="searchResult.count > this.itemPerpage">
            <span class="text-primary-dark">{{ $t('Showing {from} to {to} of {total} entries', {from: ((currentPage - 1) * this.itemPerpage) + 1, to: toItem, total: searchResult.count}) }}</span>
          </div>
          <div v-if="searchResult.count > this.itemPerpage">
            <b-pagination
              v-model="currentPage"
              :total-rows="searchResult.count"
              :per-page="this.itemPerpage"
              :first-text="$t('First')"
              :prev-text="$t('Prev')"
              :next-text="$t('Next')"
              :last-text="$t('Last')"
              page-class="font-weight-bold"
            ></b-pagination>
          </div>
        </b-row>
      </div>
    </b-skeleton-wrapper>
  </div>
  <!--<div class="container base-container-x pb-4 bg-white" v-if="this.payloadSearch.search_type == 'school'">
    <b-row class="section-3">
      <b-col lg="12" sm="12" class="pt-4">
        <div>
          <h3 class="section-header-title text-primary-dark">{{ $t('Popular Yoga School') }}</h3>
          <h5 class="text-gray-light" style="font-size:0.9rem;">{{ $t('INTERNATIONAL DIRECTORY OF YOGA SCHOOL') }}</h5>
        </div>
      </b-col>
      <b-col lg="12" sm="12">
        <b-skeleton-wrapper>
          <template>
            <div v-if="isLoadingRecommended">
            <b-card class="border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            </div>
          </template>
            <ResultSearchItem v-if="schooolRecommended" :results="schooolRecommended" searchType="school"/>
            <div v-else class="text-center justify-content-center py-5 mt-3">
              <div class="search-notfound-container">
                <h5 class="d-block pt-3">{{ $t('No results') }}</h5>
                <span class="d-block">{{ $t('Popular Yoga School') }}</span>
              </div>
            </div>
         </b-skeleton-wrapper>
      </b-col>
      <b-col class="d-none" lg="12" sm="12">
         <b-skeleton-wrapper>
          <template>
            <div v-if="isLoadingRecommended">
            <b-card class="border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            </div>
          </template>
            <vue-horizontal-list v-if="schooolRecommended.length > 0" :items="schooolRecommended" :options="options">
              <template v-slot:default="{item}">
                <div class="item item-popular rounded-lg">
                  <div class="item-img text-center" style="posion:relative">
                    <router-link :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }">
                      <b-img center blank-color="#fff" class="border-0 rounded-top img-item w-100" style="object-position: center;" :src="item.doc_logo ? item.doc_logo : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img>
                      </router-link>
                  </div>
                  <div class="p-3">
                    <p class="text-danger-light">{{ item.region_name }}</p>
                    <h5 class="text-primary-dark">{{ item.school_name }}</h5>
                    <span class="small text-gray-light">{{ $t('Member Since') }} :</span> <span class="small text-primary-dark">{{ new Date(item.member_since) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}</span><br>
                    <b-button pill variant="link" :to="{ name: 'schoolProfileDetail', params: { schoolId: item.id } }" class="font-weight-bold text-primary-light pl-0">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                  </div>
                </div>
              </template>
            </vue-horizontal-list>
            <div v-else class="text-center justify-content-center py-5 mt-3">
              <div class="search-notfound-container">
                <h5 class="d-block pt-3">{{ $t('No results') }}</h5>
                <span class="d-block">{{ $t('Popular Yoga School') }}</span>
              </div>
            </div>
         </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </div>-->
  <!--<div class="container base-container-x pb-4 mt-4" v-if="this.payloadSearch.search_type == 'teacher'">
    <b-row class="section-3">
      <b-col lg="12" sm="12">
        <div>
          <h3 class="section-header-title text-primary-dark">{{ $t('Recommended Yoga Teacher') }}</h3>
          <h5 class="text-gray-light" style="font-size:0.9rem;">{{ $t('INTERNATIONAL DIRECTORY OF YOGA TEACHER') }}</h5>
        </div>
      </b-col>
      <b-col lg="12" sm="12">
        <b-skeleton-wrapper>
          <template>
            <div v-if="isLoadingRecommended">
            <b-card class="border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            </div>
          </template>
            <ResultSearchItem v-if="teacherRecommended" :results="teacherRecommended" searchType="teacher"/>
            <div v-else class="text-center justify-content-center py-5 mt-3">
              <div class="search-notfound-container">
                <h5 class="d-block pt-3">{{ $t('No results') }}</h5>
                <span class="d-block">{{ $t('Recommended Yoga Teacher') }}</span>
              </div>
            </div>
         </b-skeleton-wrapper>
      </b-col>
      <b-col class="d-none" lg="12" sm="12">
        <div>
         <vue-horizontal-list :items="teacherRecommended" :options="options1">
            <template v-slot:default="{item}">
              <div class="item item-recommended-teacher">
                 <router-link :to="{ name: 'teacherProfileDetail', params: { teacherId: item.id } }"><b-img rounded="circle" height="160" width="160" center :src="item.doc_avatar || item.doc_cover ? (item.doc_avatar || item.doc_cover) : PUBLIC_PATH+'img/icons/android-chrome-512x512.png'"></b-img></router-link>
                <div class="p-3 text-center">
                  <p class="text-danger-light">{{ item.region_name }}</p>
                  <h5 class="text-primary-dark">{{ item.name }}</h5>
                  <span class="small text-gray-light">{{ $t('Member Since') }}:</span><span class="small text-primary-dark"> {{ new Date(item.member_since) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}</span><br>
                  <br>
                  <b-button :to="{ name: 'teacherProfileDetail', params: { teacherId: item.id } }" pill class="font-weight-bold btn-read-more mt-2 px-3 mt-2">{{ $t('Read more') }}</b-button>
                </div>
              </div>
            </template>
          </vue-horizontal-list>
        </div>
      </b-col>
    </b-row>
  </div>-->
  <div class="container base-container-x py-4 mb-4 wya-certified-list" v-if="payloadSearch.search_type == 'event'">
    <b-row class="">
      <b-col lg="12" sm="12">
        <h3 class="section-header-title text-primary-dark d-flex">{{ $t('Top Training & Event') }}</h3>
        <h5 class="text-gray-light" style="font-size:0.9rem;">{{ $t('WYA – AUTHORISED YOGA TEACHER TRAININGS')}}</h5>
      </b-col>
        <b-col lg="12" sm="12">
        <b-skeleton-wrapper>
          <template>
            <div v-if="isLoadingRecommended">
            <b-card class="border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-card class="mt-3 border-0">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            </div>
          </template>
            <ResultSearchItem v-if="eventRecommended" :results="eventRecommended" searchType="event"/>
            <div v-else class="text-center justify-content-center py-5 mt-3">
              <div class="search-notfound-container">
                <h5 class="d-block pt-3">{{ $t('No results') }}</h5>
                <span class="d-block">{{ $t('Recommended event') }}</span>
              </div>
            </div>
         </b-skeleton-wrapper>
      </b-col>
    </b-row>
    <b-row class="d-none">
      <b-col lg="12" sm="12">
        <b-row>
        <b-col lg="6" sm="12" class="px-0 mt-4">
          <b-card
            title="Thailand"
            title-tag="h5"
            sub-title-tag="h3"
            :img-src="require('../assets/images/banner/school_ex_1.png')"
            img-alt="Image"
            img-top
            body-class="px-lg-2"
            style="max-width: 100%; border:none"
            class="mb-2"
          >
          <b-card-sub-title>
            <div class="d-flex d-block justify-content-between">
              <h5>200 HOUR / 250 HOURS REGISTERED AND CERTIFIED AERIAL YOGA SCHOOL</h5>
            </div>
            <p class="float-left text-gray-light">Oct 16 - Oct 19 | 15:30 pm - 16:30 pm</p><br><br>
            <div>
              <b-button href="#" pill variant="link" class="font-weight-bold text-primary-light float-left px-0">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
              <h5 class="text-danger-light float-right mt-2">$8.5</h5>
            </div>
          </b-card-sub-title>
        </b-card>
        </b-col>
        <b-col lg="6" sm="12" class="mt-4 list">
          <b-container>
            <b-row class="mb-4">
              <b-col xl="5" lg="6" sm="12" class="px-0">
                <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
                <b-img rounded height="200px" class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
              </b-col>
              <b-col sm="6" lg="6" xl="7" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div class="w-100">
                  <h5 class="text-danger-light mt-lg-2 text-item-training">Thailand</h5>
                  <h5 class="text-primary-light">YOGA AROGYA</h5>
                  <span class="float-left text-gray-light">Oct 19 2020 | 15:30 pm - 16:30 pm</span><br>
                  <div class="row align-items-center">
                    <div class="col-7 pr-0">
                      <b-button href="#" pill variant="link" class="font-weight-bold text-item-training text-primary-light pl-0 mt-lg-2 float-left">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                    </div>
                    <div class="col-5 pr-0">
                      <span class="text-danger-light text-item-training float-right mt-2">$8.5</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col xl="5" lg="6" sm="12" class="px-0">
                <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
                <b-img rounded height="200px" class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>              </b-col>
              <b-col sm="6" lg="6" xl="7" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div class="w-100">
                  <h5 class="text-danger-light mt-lg-2 text-item-training">Thailand</h5>
                  <h5 class="text-primary-light">YOGA AROGYA</h5>
                  <span class="float-left text-gray-light">Oct 19 2020 | 15:30 pm - 16:30 pm</span><br>
                  <div class="row align-items-center">
                    <div class="col-7 pr-0">
                      <b-button href="#" pill variant="link" class="font-weight-bold text-item-training text-primary-light pl-0 mt-lg-2 float-left">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                    </div>
                    <div class="col-5 pr-0">
                      <span class="text-danger-light text-item-training float-right mt-2 pr-sm-0">$8.5</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col xl="5" lg="6" sm="12" class="px-0">
                <b-img rounded fluid class="d-sm-block d-lg-none d-md-none d-xl-none" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>
                <b-img rounded height="200px" class="d-none d-lg-block d-md-block d-xl-block" :src="require('../assets/images/banner/school_ex_1.png')"></b-img>              </b-col>
              <b-col sm="6" lg="6" xl="7" col class="float-left d-flex align-items-center pl-lg-0 pl-sm-0">
                <div class="w-100">
                  <h5 class="text-danger-light mt-lg-2 text-item-training">Thailand</h5>
                  <h5 class="text-primary-light">YOGA AROGYA</h5>
                  <span class="float-left text-gray-light">Oct 19 2020 | 15:30 pm - 16:30 pm</span><br>
                  <div class="row align-items-center">
                    <div class="col-7 pr-0">
                      <b-button href="#" pill variant="link" class="font-weight-bold text-item-training text-primary-light pl-0 mt-lg-2 float-left">{{ $t('Read more') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
                    </div>
                    <div class="col-5 pr-0">
                      <span class="text-danger-light d-block text-item-training float-right mt-2 pr-sm-0">$8.5</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
  </div>
</template>
<script>
import BlockSearch from '../components/search/BlockSearch.vue'
// import VueHorizontalList from 'vue-horizontal-list'
import ResultSearchItem from '../components/search/ResultSearchItem'
import Api from '../api/api'

const axios = require('axios')

export default {
  name: 'SearchDirectory',
  title: ' | Find a Yoga Schools or Teachers',
  components: {
    BlockSearch,
    // VueHorizontalList,
    ResultSearchItem
  },
  data () {
    return {
      loading: true,
      isSubmitSearch: false,
      isSearch: false,
      heightWindow: window.innerWidth,
      center: { lat: 31.205753, lng: 29.924526 },
      zoom: 2,
      markers: [],
      infoContent: {
        type: null,
        id: null,
        name: null,
        address: null,
        image: null
      },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      options: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 4 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,
          // Because: #app {padding: 80px 24px;}
          padding: 10
        }
      },
      payloadSearch: {
        search_type: 'school',
        school_name: '',
        instructors_name: '',
        traning: '',
        region: {
          value: null,
          text: null
        },
        style_of_yoga: null,
        start_date: new Date(),
        end_date: new Date()
      },
      options1: {
        responsive: [
          { end: 576, size: 1 },
          { start: 576, end: 768, size: 2 },
          { start: 768, end: 992, size: 3 },
          { size: 5 }
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,
          // Because: #app {padding: 80px 24px;}
          padding: 12
        }
      },
      isLoading: false,
      isLoadingRecommended: false,
      searchResult: null,
      currentPage: 1,
      itemPerpage: 20,
      toItem: 0,
      schooolRecommended: null,
      teacherRecommended: null,
      eventRecommended: null,
      googleMapKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      googleMapBaseUrl: 'https://maps.googleapis.com/maps/api/geocode/json'
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    /* this.toggleNavbarNavClass('addClass', 'nav-text-shadow') */
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.payloadSearch = { ...this.payloadSearch, ...this.$store.getters.formSearchDirectory }
    if (this.payloadSearch.region.value || this.payloadSearch.school_name || this.payloadSearch.style_of_yoga) { // || this.payloadSearch.start_date
      this.isSearch = true
      if (this.payloadSearch.search_type === 'school') {
        this.getSchool(false, false)
        this.allSchoolMarker()
      } else if (this.payloadSearch.search_type === 'event') {
        this.getEvent(false, false)
        // this.allEventMarker()
      } else if (this.payloadSearch.search_type === 'teacher') {
        if (this.payloadSearch.teacher_name) {
          this.getTeacher(false, false)
        } else {
          // this.getTeacherRecommended(false)
        }
        this.allTeacherMarker()
      }
    } else {
      this.getSchoolRecommended()
      this.allSchoolMarker()
    }
    this.loading = false
  },
  watch: {
    currentPage: function () {
      if (this.payloadSearch.search_type === 'school') {
        this.getSchool(false, true)
      } else if (this.payloadSearch.search_type === 'event') {
        this.getEvent(false, true)
      } else if (this.payloadSearch.search_type === 'teacher') {
        this.getTeacher(false, true)
      }
    }
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = {
        type: marker.type,
        id: marker.id,
        name: marker.name,
        address: marker.address,
        image: marker.image
      }
      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    async geocodeAddress (type, id, adrs, name, image) {
      if (adrs !== '') {
        try {
          const address = encodeURI(adrs)
          const url = this.googleMapBaseUrl + '?key=' + this.googleMapKey + '&address=' + address
          await axios.get(url).then(({ data }) => {
            const geocodedLocation = data.results[0]
            this.markers.push({
              type: type,
              id: id,
              position: { lat: parseFloat(geocodedLocation.geometry.location.lat), lng: parseFloat(geocodedLocation.geometry.location.lng) },
              address: adrs,
              name: name,
              image: image
            })
          })
        } catch (e) {}
      }
    },
    onSelectTab (value) {
      // this.markers = []
      this.isSearch = false
      this.searchResult = null
      this.payloadSearch = { ...this.payloadSearch, ...value }
      if (value.search_type === 'school') {
        this.getSchoolRecommended()
        this.allSchoolMarker()
      } else if (value.search_type === 'event') {
        this.getEventRecommended()
        // this.allEventMarker()
      } else if (value.search_type === 'teacher') {
        this.getTeacherRecommended(false)
        // this.allTeacherMarker()
      }
    },
    onSubmitSeach (value) {
      this.payloadSearch = { ...this.payloadSearch, ...value }
      this.isSearch = true
      if (this.payloadSearch.search_type === 'school') {
        this.getSchool(false, true)
      } else if (this.payloadSearch.search_type === 'event') {
        this.getEvent(false, true)
      } else if (this.payloadSearch.search_type === 'teacher') {
        this.getTeacher(false, true)
      }
    },
    toggleNavbarNavClass (addRemoveClass, className) {
      const nav = document.querySelector('.navbar-nav')
      if (addRemoveClass === 'addClass') {
        nav.classList.add(className)
      } else {
        nav.classList.remove(className)
      }
    },
    nextPageSchool () {
      this.currentPage++
      if (this.payloadSearch.search_type === 'school') {
        this.getSchool(true, true)
      } else if (this.payloadSearch.search_type === 'event') {
        this.getEvent(true)
      } else if (this.payloadSearch.search_type === 'teacher') {
        this.getTeacher(true)
      }
    },
    getSchool (isViewMore, isRenderMarker) {
      const payload = {
        region: this.payloadSearch.region.value,
        school_type: this.payloadSearch.type_option,
        page: this.currentPage,
        limit: this.itemPerpage,
        offset: (this.currentPage - 1) * this.itemPerpage,
        search: this.payloadSearch.school_name,
        status: 2
      }
      this.isLoading = true
      Api.listSchool(payload).then(response => {
        if (response.data.results) {
          if (isViewMore) {
            this.searchResult.push(response.data)
          } else {
            this.searchResult = response.data
          }
          // if (isRenderMarker) {
          //   this.markers = []
          //   response.data.results.map(school => {
          //     if (school.latitude && school.longitude) {
          //       this.markers.push({
          //         type: 'school',
          //         id: school.id,
          //         position: { lat: parseFloat(school.latitude), lng: parseFloat(school.longitude) },
          //         address: school.school_address || school.region_name,
          //         name: school.school_name,
          //         image: school.doc_logo
          //       })
          //     }
          //     else if (school.latitude === null || school.longitude === null) {
          //       let address = school.school_address
          //       if (school.region_name) {
          //         address = address + ' ' + school.region_name
          //       }
          //       this.geocodeAddress('school', school.id, address, school.school_name, school.doc_logo)
          //     }
          //   })
          // }
          this.toItem = (parseInt(this.searchResult.count) < this.itemPerpage ? this.searchResult.count : (this.currentPage * this.itemPerpage))
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    allSchoolMarker () {
      Api.schoolMarker({ limit: 2000 }).then(response => {
        if (response.data.results) {
          this.markers = []
          response.data.results.forEach(school => {
            if (school.latitude && school.longitude) {
              this.markers.push({
                type: 'school',
                id: school.id,
                position: { lat: parseFloat(school.latitude), lng: parseFloat(school.longitude) },
                address: school.school_address || school.region_name,
                name: school.school_name,
                image: school.doc_logo
              })
            }
            // else if (school.latitude === null || school.longitude === null) {
            //   let address = school.school_address
            //   if (school.region_name) {
            //     address = address + ' ' + school.region_name
            //   }
            //   this.geocodeAddress('school', school.id, address, school.school_name, school.doc_logo)
            // }
          })
        }
      })
    },
    getEvent (isViewMore, isRenderMarker) {
      const date = new Date(this.payloadSearch.start_date)
      const y = date.getFullYear()
      const m = date.getMonth()
      const firstDayOfMonth = new Date(y, m, 1)
      const payload = {
        region: this.payloadSearch.region.value,
        school_style_of_yoga: this.payloadSearch.style_of_yoga,
        start_date__gte: this.$options.filters.dateFormat(new Date(firstDayOfMonth), 'YYYY-MM-DD'),
        end_date__lte: this.$options.filters.dateFormat(new Date(this.payloadSearch.e_date), 'YYYY-MM-DD'),
        page: this.currentPage,
        limit: this.itemPerpage,
        offset: (this.currentPage - 1) * this.itemPerpage,
        search: this.payloadSearch.traning
      }
      this.isLoading = true
      Api.event(payload).then(response => {
        if (response.data.results) {
          if (isViewMore) {
            this.searchResult.push(response.data)
          } else {
            this.searchResult = response.data
          }
          this.toItem = (parseInt(this.searchResult.count) < this.itemPerpage ? this.searchResult.count : (this.currentPage * this.itemPerpage))
          // if (isRenderMarker) {
          //   this.markers = []
          //   response.data.results.map(event => {
          //     if (event.latitude && event.longitude) {
          //       this.markers.push({
          //         type: 'event',
          //         id: event.id,
          //         position: { lat: parseFloat(event.latitude), lng: parseFloat(event.longitude) },
          //         address: event.address || event.region_name,
          //         name: event.subject,
          //         image: event.image_small
          //       })
          //     } else if (event.latitude === undefined || event.latitude === undefined) {
          //       let address = event.address
          //       if (event.region_name) {
          //         address = address + ' ' + event.region_name
          //       }
          //       if (event.region_country_name) {
          //         address = address + ' ' + event.region_country_name
          //       }
          //       this.geocodeAddress('event', event.id, address, event.subject, event.image_small)
          //     }
          //   })
          // }
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getEventRecommended () {
      this.isLoadingRecommended = true
      const date = new Date()
      const y = date.getFullYear()
      const m = date.getMonth()
      const firstDayOfMonth = new Date(y, m, 1)
      const payload = {
        start_date__gte: this.$options.filters.dateFormat(new Date(firstDayOfMonth), 'YYYY-MM-DD'),
        limit: 28,
        is_recommended: 1
      }
      Api.event(payload).then(response => {
        if (response.data.results) {
          this.eventRecommended = response.data
        }
        this.isLoadingRecommended = false
      }).catch(() => {
        this.isLoadingRecommended = false
      })
    },
    allEventMarker () {
      Api.eventMarker({ limit: 3000 }).then(response => {
        if (response.data.results) {
          this.markers = []
          response.data.results.forEach(event => {
            if (event.latitude && event.longitude) {
              this.markers.push({
                type: 'event',
                id: event.id,
                position: { lat: parseFloat(event.latitude), lng: parseFloat(event.longitude) },
                address: event.address || event.region_name,
                name: event.subject,
                image: event.image_small
              })
            } else if (event.latitude === undefined || event.latitude === undefined) {
              let address = event.address
              if (event.region_name) {
                address = address + ' ' + event.region_name
              }
              if (event.region_country_name) {
                address = address + ' ' + event.region_country_name
              }
              this.geocodeAddress('event', event.id, address, event.subject, event.image_small)
            }
          })
        }
      })
    },
    getTeacher (isViewMore, isRenderMarker) {
      const payload = {
        region: this.payloadSearch.region.value,
        teacher_apply_type: this.payloadSearch.type_option,
        start_date__gte: this.$options.filters.dateFormat(new Date(this.payloadSearch.start_date), 'YYYY-MM-DD'),
        end_date__lte: this.$options.filters.dateFormat(new Date(this.payloadSearch.e_date), 'YYYY-MM-DD'),
        page: this.currentPage,
        limit: this.itemPerpage,
        offset: (this.currentPage - 1) * this.itemPerpage,
        search: this.payloadSearch.teacher_name,
        status: 2
      }
      this.isLoading = true
      Api.teacher(payload).then(response => {
        if (response.data.results) {
          if (isViewMore) {
            this.searchResult.push(response.data)
          } else {
            this.searchResult = response.data
          }
          this.toItem = (parseInt(this.searchResult.count) < this.itemPerpage ? this.searchResult.count : (this.currentPage * this.itemPerpage))
          // if (isRenderMarker) {
          //   this.markers = []
          //   response.data.results.map(teacher => {
          //     if (teacher.latitude && teacher.longitude) {
          //       this.markers.push({
          //         type: 'teacher',
          //         id: teacher.id,
          //         position: { lat: parseFloat(teacher.latitude), lng: parseFloat(teacher.longitude) },
          //         address: teacher.address || teacher.region,
          //         name: teacher.name,
          //         image: teacher.doc_avatar
          //       })
          //     } else if ((teacher.latitude === null || teacher.latitude === undefined) || (teacher.longitude === null || teacher.longitude === undefined)) {
          //       let address = teacher.address
          //       if (teacher.region_name) {
          //         address = address + ' ' + teacher.region_name
          //       }
          //       this.geocodeAddress('teacher', teacher.id, address, teacher.name, teacher.doc_avatar)
          //     }
          //   })
          // }
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    allTeacherMarker () {
      Api.teacherMarker({ limit: 3000 }).then(response => {
        if (response.data.results) {
          this.markers = []
          response.data.results.forEach(teacher => {
            if (teacher.latitude && teacher.longitude) {
              this.markers.push({
                type: 'teacher',
                id: teacher.id,
                position: { lat: parseFloat(teacher.latitude), lng: parseFloat(teacher.longitude) },
                address: teacher.address || teacher.region,
                name: teacher.name,
                image: teacher.doc_avatar
              })
            } else if ((teacher.latitude === null || teacher.latitude === undefined) || (teacher.longitude === null || teacher.longitude === undefined)) {
              let address = teacher.address
              if (teacher.region_name) {
                address = address + ' ' + teacher.region_name
              }
              this.geocodeAddress('teacher', teacher.id, address, teacher.name, teacher.doc_avatar)
            }
          })
        }
      })
    },
    getSchoolRecommended () {
      const payload = {
        is_recommended: 1,
        limit: 30,
        offset: 0
      }
      this.isLoadingRecommended = true
      Api.listSchool(payload).then(response => {
        if (response.data.results) {
          this.schooolRecommended = response.data
        }
        this.isLoadingRecommended = false
      }).catch(() => {
        this.isLoadingRecommended = false
      })
    },
    getTeacherRecommended (isRenderMarker) {
      const payload = {
        is_recommended: 1,
        limit: 30,
        offset: 0
      }
      this.isLoadingRecommended = true
      Api.teacher(payload).then(response => {
        if (response.data.results) {
          this.teacherRecommended = response.data
          // if (isRenderMarker) {
          //   this.markers = []
          //   response.data.results.map(teacher => {
          //     if (teacher.latitude && teacher.longitude) {
          //       this.markers.push({
          //         type: 'teacher',
          //         id: teacher.id,
          //         position: { lat: parseFloat(teacher.latitude), lng: parseFloat(teacher.longitude) },
          //         address: teacher.address || teacher.region,
          //         name: teacher.name,
          //         image: teacher.doc_avatar
          //       })
          //     } else if ((teacher.latitude === null || teacher.latitude === undefined) || (teacher.longitude === null || teacher.longitude === undefined)) {
          //       let address = teacher.address
          //       if (teacher.region_name) {
          //         address = address + ' ' + teacher.region_name
          //       }
          //       this.geocodeAddress('teacher', teacher.id, address, teacher.name, teacher.doc_avatar)
          //     }
          //   })
          // }
        }
        this.isLoadingRecommended = false
      }).catch(() => {
        this.isLoadingRecommended = false
      })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleNavbarNavClass('removeClass', 'nav-text-shadow')
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style scoped>
  .search-intro-text {
    margin-bottom: 120px;
    color: white;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.75);
  }
  .search-heading {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }
  .search-subheading {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }
  .section-1 {
    min-height:120px;
  }
  .bg-section-2 {
    min-height: 600px !important;
  }
  .section-2 p, .section-1 p {
    font-size: 1.3rem;
  }
  .add-icon {
    font-size: 40px;
  }
  .btn-read-more, .btn-subscribe{
    background-color: #3B6098 !important;
    border-color: #3B6098 !important;
  }
  .testimonial-text {
    font-size: 1.2rem;
    color: #2c3e50;
    text-shadow: none;
  }
  .block-search {
    /*margin-top: -70px;*/
  }
  /*.item-popular {
    background-color: #f5f7faf7;
    border: 1px solid #f5f7faf7;
  }*/
  .item-recommended-teacher {
    background-color: #fff;
  }
  .btn-read-more {
    background-color: #3B6098 !important;
    border-color: #3B6098 !important;
  }
  .img-item {
    width: 100%;
    max-width: 255px;
  }
  @media screen and (max-width: 576px) {
    .search-heading {
    font-size: 1.5rem;
  }
  .search-subheading {
    font-size: 1rem;
  }
    .text-item-training {
      font-size: 12px;
    }
    .item-img-wrapper {
      background-color: #fdfdfd;
    }
    .img-item {
      object-fit: none;
      object-position: center;
      height: 200px;
      width: 100%;
    }
}
</style>
